import colors from "church_center/utils/colors"

const themes = {
  primary: {
    activeColor: colors.topaz,
    bgColor: colors.tint5,
  },
  secondary: {
    activeColor: colors.turquoise,
    bgColor: colors.tint5,
  },
  tertiary: {
    activeColor: colors.marigold,
    bgColor: colors.tint5,
  },
}

export default themes
