import React from "react"
import { FormFor, HiddenFieldTag } from "react-rails-form-helpers"
import Ladda from "shared/components/ladda"
import PersonFormFields from "church_center/components/donation_form/person_form_fields"
import PaymentMethodFormFields from "church_center/components/donation_form/payment_method_form_fields"
import findPaymentMethodTokenResolver from "church_center/components/find_payment_method/find_payment_method_token_resolver"
import { bool, func, shape, oneOfType, number, string, object } from "prop-types"

SubmitActivationForm.propTypes = {
  loading: bool.isRequired,
  onSubmit: func.isRequired,
  acceptedToS: bool.isRequired,
  person: shape({
    account_center_id: oneOfType([number, string]).isRequired,
    email_address: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  paymentMethod: shape({
    strategy: string.isRequired,
    payload: object.isRequired,
  }).isRequired,
  submitText: string.isRequired,
  url: string.isRequired,
}

function SubmitActivationForm({
  loading,
  onSubmit,
  person,
  paymentMethod,
  acceptedToS,
  submitText,
  url,
}) {
  return (
    <FormFor url={url} method="put" onSubmit={onSubmit} data-remote>
      <PersonFormFields person={person} />
      <PaymentMethodFormFields paymentMethod={paymentMethod} />
      <HiddenFieldTag readOnly name="stripe[store_payment_method]" value="true" />
      {acceptedToS && <HiddenFieldTag readOnly name="accepted_tos" value="true" />}
      <div className="mt-3 ta-c">
        <Ladda button loading={loading} className="btn">
          {submitText}
        </Ladda>
      </div>
    </FormFor>
  )
}

export default findPaymentMethodTokenResolver(SubmitActivationForm)
