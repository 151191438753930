import React from "react"
import { string } from "prop-types"

const PaymentMethodIcon = ({ name }) => {
  const iconDangerousHTML = {
    __html: `<use xlink:href="#icon-svg-${name}"></use>`,
  }

  return (
    <svg
      className="icon-svg icon-svg--payment icon-svg--inline-card p-r"
      style={{ top: "1px" }}
      dangerouslySetInnerHTML={iconDangerousHTML}
    />
  )
}

PaymentMethodIcon.propTypes = {
  name: string.isRequired,
}

export default PaymentMethodIcon
