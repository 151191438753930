import MoneyInput from "shared/components/money_input"
import RepeatableSchedules from "shared/runners/repeatable_schedules"
import { isNaN, invert } from "lodash"

export function designationsAmountToString(designations) {
  if (designations.length === 1) {
    return designations[0].amount
  }

  const sum = designations.reduce((m, d) => m + (parseFloat(d.amount) || 0), 0)
  return MoneyInput.minimalTextValue(sum)
}

export function designationsAmountToNumber(designations) {
  const amount = parseFloat(designationsAmountToString(designations))
  return isNaN(amount) ? 0 : amount
}

export function designationsMinimalString(designations) {
  return MoneyInput.minimalTextValue(designationsAmountToNumber(designations))
}

export function designationsAmountCents(designations) {
  const floatDollars = designationsAmountToNumber(designations)
  const intCents = Math.floor(floatDollars * 100)
  return intCents
}

export function scheduleFrequency(schedule) {
  const frequency = RepeatableSchedules.getFrequency(schedule)
  return invert(RepeatableSchedules.frequencies)[frequency].toLowerCase()
}
