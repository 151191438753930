import React from "react"
import appConfig from "shared/runners/app_config"
import { string } from "prop-types"

export default class LogoutButton extends React.Component {
  static propTypes = {
    className: string,
    submitText: string,
  }

  static defaultProps = {
    submitText: "Log out",
  }

  handleLogout = (e) => {
    e.preventDefault()

    $.ajax({
      method: "GET",
      url: appConfig.church_center.unified_logout_url,
    }).done(() => {
      parent.postMessage({ type: "close" }, "*")
    })
  }

  render() {
    const { className, submitText } = this.props

    return (
      <a onClick={this.handleLogout} className={className} tabIndex="0">
        {submitText}
      </a>
    )
  }
}
