import React from "react"
import appConfig from "shared/runners/app_config"
import { mergeUrlParams } from "shared/utils/location"
import { string } from "prop-types"

LoginButton.propTypes = {
  className: string,
  submitText: string,
  emailAddress: string,
  redirectUrl: string,
}

function LoginButton({ className = "", submitText = "login", emailAddress, redirectUrl }) {
  const params = { value: emailAddress }
  if (redirectUrl) {
    params.return = redirectUrl
  }
  const href = mergeUrlParams(appConfig.church_center.unified_login_url, params)

  return (
    <a href={href} className={className} tabIndex="0">
      {submitText}
    </a>
  )
}

export default LoginButton
