import React from "react"
import { node } from "prop-types"

const PaymentMethodList = ({ children }) => <div>{children}</div>

PaymentMethodList.propTypes = {
  children: node.isRequired,
}

export default PaymentMethodList
