import React from "react"
import { object, bool, string, oneOf, arrayOf, func } from "prop-types"
import ApplePayButton from "church_center/components/find_payment_method/apple_pay_button"
import classNames from "classnames"
import LoginButton from "church_center/components/find_payment_method/login_button"
import PaymentMethodList from "church_center/components/find_payment_method/payment_method_list"
import PaymentMethodChoice from "church_center/components/find_payment_method/payment_method_choice"
import ErrorMessage from "church_center/components/shared/error_message"
import Ladda from "shared/components/ladda"
import * as StripeWithGuard from "shared/runners/stripe_with_guard"
import * as StripeConnections from "church_center/runners/stripe_connections"
import { ccoPerson, paymentMethod, inProgressDonationFormState } from "shared/utils/prop_types"
import { assign, capitalize, merge, noop, pick } from "lodash"
import Icon from "church_center/components/external_icon"
import colors, { convertDoxyColorToHex } from "church_center/utils/colors"
import { css } from "glamor"
import { featureEnabled } from "shared/runners/flipper"
import appConfig from "shared/runners/app_config"
import { mergeUrlParams } from "shared/utils/location"
import SelectTestCard from "church_center/components/select_test_card"

const CHOOSE_STRATEGY = "CHOOSE_STRATEGY"
const USING_STORED_PAYMENT_METHOD = "USING_STORED_PAYMENT_METHOD"
const STRIPE_APPLE_PAY_SUCCESS = "STRIPE_APPLE_PAY_SUCCESS"
const STRIPE_APPLE_PAY_IN_PROGRESS = "STRIPE_APPLE_PAY_IN_PROGRESS"
const STRIPE_CARD_IN_PROGRESS = "STRIPE_CARD_IN_PROGRESS"
const STRIPE_CARD_SUCCESS = "STRIPE_CARD_SUCCESS"
const STRIPE_CARD_FAILURE = "STRIPE_CARD_FAILURE"
const CHOOSE_BANK_VERIFICATION_STRATEGY = "CHOOSE_BANK_VERIFICATION_STRATEGY"
const STRIPE_BANK_IN_PROGRESS = "STRIPE_BANK_IN_PROGRESS"
const STRIPE_BANK_SUCCESS = "STRIPE_BANK_SUCCESS"
const STRIPE_BANK_FAILURE = "STRIPE_BANK_FAILURE"
const STRIPE_CONNECTIONS_INSTANT_SUCCESS = "STRIPE_CONNECTIONS_INSTANT_SUCCESS"
const STRIPE_CONNECTIONS_INSTANT_CONFIRMED = "STRIPE_CONNECTIONS_INSTANT_CONFIRMED"
const STRIPE_CONNECTIONS_MICRODEPOSIT_SUCCESS = "STRIPE_CONNECTIONS_MICRODEPOSIT_SUCCESS"
const STRIPE_CONNECTIONS_MICRODEPOSIT_CONFIRMED = "STRIPE_CONNECTIONS_MICRODEPOSIT_CONFIRMED"
const STRIPE_CONNECTIONS_FAILURE = "STRIPE_CONNECTIONS_FAILURE"
const UNRESOLVED = "UNRESOLVED"

function buildInitialStateFromProps(props) {
  return {
    step: CHOOSE_STRATEGY,
    isViewingStripeConnectionsModal: false,
    stored_payment_method_id: null,
    stripe: {
      input: {
        account_holder_name: props.person.name,
        account_holder_type: "individual",
        account_number: "",
        country: props.country,
        currency: props.currency,
        routing_number: "",
        number: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
      },
      response: null,
    },
    loading: false,
  }
}

export default class LegacyFindPaymentMethod extends React.Component {
  static propTypes = {
    applePay: object.isRequired,
    acceptApplePay: bool.isRequired,
    cardGivingLevel: string.isRequired,
    country: oneOf(["US", "CA"]),
    currency: oneOf(["USD", "CAD"]),
    paymentMethods: arrayOf(paymentMethod),
    person: ccoPerson.isRequired,
    isRecurring: bool,
    achAllowed: bool.isRequired,
    children: func.isRequired,
    feeCoverageAllowed: bool,
    isCoveringFee: bool,
    onChangeCoveringFee: func,
    onReset: func,
    loginRedirectUrl: string,
    unhandledGivingError: string,
    isDonating: bool,
    onChange: func,
    inProgressDonationFormState,
    newInProgressDonationUrl: string,
    ttgSetup: bool,
  }

  static defaultProps = {
    paymentMethods: [],
    feeCoverageAllowed: false,
    isCoveringFee: false,
    applePaySupported: false,
    onChangeCoveringFee: () => undefined,
    onReset: () => undefined,
    isDonating: true,
    ttgSetup: false,
  }

  constructor(props) {
    super(props)
    this.state = buildInitialStateFromProps(props)
  }

  componentDidMount() {
    StripeWithGuard.promise().then(() => {
      this.stripe = StripeWithGuard.instance()
      this.determineApplePaySupport()
    })
    this.doImperativeWork()
  }

  componentDidUpdate(prevProps, prevState) {
    this.doImperativeWork(prevProps, prevState)
  }

  handleApplePaySuccess = (response, completion) => {
    const step = STRIPE_APPLE_PAY_SUCCESS

    const stripe = { ...this.state.stripe, response, applePay: { completion } }
    this.setState({ step, stripe })
  }

  handleStripeConnectionsSuccess = (response) => {
    const stripe = { error: null, response }
    let step
    const { financial_connections_account } = response.setupIntent.payment_method.us_bank_account

    if (financial_connections_account === null) {
      step = STRIPE_CONNECTIONS_MICRODEPOSIT_SUCCESS
    } else {
      step = STRIPE_CONNECTIONS_INSTANT_SUCCESS
    }

    this.setState({ step, stripe, isViewingStripeConnectionsModal: false, loading: false })
  }

  handleStripeConnectionsError = (error) => {
    const stripe = { error, response: null }
    const step = STRIPE_CONNECTIONS_FAILURE
    this.setState({ stripe, step, isViewingStripeConnectionsModal: false, loading: false })
  }

  handleStripeConnectionsSubmit = () => {
    const { client_secret } = this.state.stripe.response.setupIntent

    this.setState({ loading: true }, () => {
      StripeConnections.confirmBankAccount({
        clientSecret: client_secret,
        onSuccess: this.handleStripeConnectionsConfirmation,
        onError: this.handleStripeConnectionsError,
      })
    })
  }

  handleStripeConnectionsConfirmation = (response) => {
    const stripe = { error: null, response }
    let step

    if (response.setupIntent.status === "succeeded") {
      step = STRIPE_CONNECTIONS_INSTANT_CONFIRMED
    } else if (
      response.setupIntent.next_action &&
      response.setupIntent.next_action.type === "verify_with_microdeposits"
    ) {
      step = STRIPE_CONNECTIONS_MICRODEPOSIT_CONFIRMED
    }

    this.setState({ step, stripe, loading: false })
  }

  handleStripeBankSubmit = () => {
    const bankInputs = pick(
      this.state.stripe.input,
      "account_holder_name",
      "account_holder_type",
      "account_number",
      "country",
      "currency",
      "routing_number"
    )
    this.setState({ loading: true }, () => {
      this.stripe.createToken("bank_account", bankInputs).then(this.handleStripeBankResponse)
    })
  }

  handleStripeBankResponse = (response) => {
    const step = response.error ? STRIPE_BANK_FAILURE : STRIPE_BANK_SUCCESS
    const stripe = { ...this.state.stripe, response }
    this.setState({ step, stripe, loading: false })
  }

  handleStripeCardSubmit = () => {
    this.setState({ loading: true }, () => {
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.stripeElementsCard,
        })
        .then(this.handleStripeCardResponse)
    })
  }

  handleStripeCardResponse = (raw) => {
    const response = raw
    const step = response.error ? STRIPE_CARD_FAILURE : STRIPE_CARD_SUCCESS

    const stripe = { ...this.state.stripe, response }

    this.setState({ step, stripe, loading: false })
  }

  reset = (overrides = {}) => {
    this.props.onReset()
    this.setState(assign(buildInitialStateFromProps(this.props), overrides))
  }

  userFriendlyError = (error) => {
    switch (true) {
      case /Missing required param/.test(error):
        return "Please complete all fields and try again."
      case /bank_account\[account_number]|bank_account\[routing_number]/.test(error):
        return "There was a problem with your routing or account number. Hint: While routing numbers are 9 digits, account numbers can vary in length."
      default:
        return error
    }
  }

  setStripeField = (step, key) => {
    return ({ target: { value } }) => {
      const input = merge({}, this.state.stripe.input, { [key]: value })
      const response = {}
      this.setState({ step, stripe: { input, response } })
    }
  }

  chooseBankStrategy = () => {
    this.reset({ step: CHOOSE_BANK_VERIFICATION_STRATEGY })
    this.setState({ selected: true })
  }

  openStripeConnections = () => {
    this.chooseBankStrategy()
    this.setState({ isViewingStripeConnectionsModal: true, loading: true })
  }

  chooseStripeCardStrategy = () => {
    this.reset({ step: STRIPE_CARD_IN_PROGRESS })
    this.setState({ selected: true })
  }

  chooseApplePayStrategy = () => {
    this.reset({ step: STRIPE_APPLE_PAY_IN_PROGRESS })
    this.setState({ selected: true })
  }

  chooseStoredPaymentMethod = (id) => {
    this.reset({
      step: USING_STORED_PAYMENT_METHOD,
      stored_payment_method_id: id,
    })
    this.setState({ selected: true })
  }

  urlToSaveInProgressDonationAndLogin = () => {
    const {
      person: { email_address },
      loginRedirectUrl,
      newInProgressDonationUrl,
    } = this.props

    const inProgressDonationForm = this.props.inProgressDonationFormState

    const redirectParams = { value: email_address }
    if (loginRedirectUrl) {
      redirectParams.return = loginRedirectUrl
    }

    const params = {
      donation_form_json: JSON.stringify(inProgressDonationForm),
      redirect_url: mergeUrlParams(appConfig.church_center.unified_login_url, redirectParams),
    }

    return mergeUrlParams(newInProgressDonationUrl, params)
  }

  digState = (...keys) => {
    let subject = this.state
    do {
      subject = subject[keys.shift()]
    } while (keys.length > 0 && subject)
    return subject
  }

  determineApplePaySupport = () => {
    this.stripe
      .paymentRequest({
        country: this.props.country && this.props.country.toUpperCase(),
        currency: this.props.currency && this.props.currency.toLowerCase(),
        total: { amount: 1, label: "Check for Apple Pay Support" },
        displayItems: [],
      })
      .canMakePayment()
      .then((result) => {
        this.setState({
          applePaySupported: result && result.applePay === true,
        })
      })
  }

  doImperativeWork = (_prevProps = {}, prevState = {}) => {
    if (
      prevState.isViewingStripeConnectionsModal === false &&
      this.state.isViewingStripeConnectionsModal === true
    ) {
      return StripeConnections.connectBankAccount({
        onSuccess: this.handleStripeConnectionsSuccess,
        onExit: this.chooseBankStrategy,
        onError: this.handleStripeConnectionsError,
        personName: this.props.person.name,
        personAccountCenterId: this.props.person.account_center_id,
        personEmailAddress: this.props.person.email_address,
      })
    }

    if (prevState.step !== STRIPE_CARD_IN_PROGRESS && this.state.step === STRIPE_CARD_IN_PROGRESS) {
      this.stripeElements = this.stripe.elements()
      this.stripeElementsCard = this.stripeElements.create("card", {
        style: {
          base: {
            color: convertDoxyColorToHex(colors.tint1),
            iconColor: convertDoxyColorToHex(colors.tint4),
            lineHeight: "24px",
            fontFamily: "inherit",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: convertDoxyColorToHex(colors.tint4),
            },
          },
          invalid: {
            color: convertDoxyColorToHex(colors.ruby),
            iconColor: convertDoxyColorToHex(colors.ruby),
          },
        },
      })
      this.stripeElementsCard.on("ready", () => this.stripeElementsCard.focus())
      this.stripeElementsCard.mount(this.stripeElementsMountPoint)
    }

    if (prevState.step === STRIPE_CARD_SUCCESS && this.props.unhandledGivingError !== null) {
      this.setState({ step: STRIPE_CARD_FAILURE })
    }

    return false
  }

  shouldShowTestUI = () => {
    return (
      featureEnabled("stripe_testmode") &&
      !featureEnabled("stripe_testmode_suppress_testcardmessage")
    )
  }

  shouldUseStripeConnections = () => {
    return featureEnabled("stripe_connections")
  }

  userCanAddBankAccount = () => {
    return this.props.person.logged_in || this.props.ttgSetup
  }

  getPaymentMethodIcon = ({ brand, method_type }) => {
    const cardBrands = {
      Visa: "visa",
      MasterCard: "mastercard",
      "American Express": "amex",
      Discover: "discover",
    }
    switch (method_type) {
      case "card":
        return cardBrands[brand] || "credit-card"
      default:
        return "bank-account"
    }
  }

  getPaymentMethodText = () => {
    return this.props.achAllowed ? "cards & bank accounts" : "cards"
  }

  getPaymentMethodForFormFields = () => {
    switch (this.state.step) {
      case STRIPE_APPLE_PAY_IN_PROGRESS:
      case STRIPE_CARD_IN_PROGRESS:
      case STRIPE_CARD_FAILURE:
        return {
          strategy: UNRESOLVED,
          paymentMethodType: "card",
          payload: {},
        }
      case STRIPE_APPLE_PAY_SUCCESS:
        return {
          strategy: STRIPE_CARD_SUCCESS,
          paymentMethodType: "card",
          payload: this.state.stripe,
        }
      case STRIPE_CARD_SUCCESS:
        return {
          strategy: this.state.step,
          paymentMethodType: "card",
          payload: this.state.stripe,
        }
      case STRIPE_BANK_SUCCESS:
      case STRIPE_CONNECTIONS_INSTANT_CONFIRMED:
      case STRIPE_CONNECTIONS_MICRODEPOSIT_CONFIRMED:
        return {
          strategy: STRIPE_BANK_SUCCESS,
          paymentMethodType: "us_bank_account",
          payload: this.state.stripe,
          verificationMethod: this.getStripeVerificationMethod(),
        }
      case STRIPE_CONNECTIONS_INSTANT_SUCCESS:
      case STRIPE_CONNECTIONS_MICRODEPOSIT_SUCCESS:
        return {
          strategy: UNRESOLVED,
          paymentMethodType: "us_bank_account",
          payload: this.state.stripe,
          verificationMethod: this.getStripeVerificationMethod(),
        }
      case USING_STORED_PAYMENT_METHOD:
        return {
          strategy: USING_STORED_PAYMENT_METHOD,
          payload: { payment_method_id: this.state.stored_payment_method_id },
          paymentMethodType: this.getStoredPaymentMethodType(this.state.stored_payment_method_id),
        }
      default:
        return {
          strategy: UNRESOLVED,
          paymentMethodType: undefined,
          payload: {},
        }
    }
  }

  getCardGivingLevelText = () => {
    const { cardGivingLevel } = this.props

    if (cardGivingLevel === "no_credit_cards") {
      return "Add debit card"
    } else {
      return "Add debit/credit card"
    }
  }

  getStoredPaymentMethodType = (id) => {
    const storedPaymentMethod = this.getStoredPaymentMethod(id)

    if (storedPaymentMethod) {
      return storedPaymentMethod.method_type
    } else {
      return null
    }
  }

  getStoredPaymentMethod = (id) => {
    const { paymentMethods } = this.props

    if (paymentMethods.length === 0) {
      return null
    } else {
      return paymentMethods.filter((p) => p.id === id)[0]
    }
  }

  getStripeVerificationMethod = () => {
    switch (this.state.step) {
      case STRIPE_BANK_SUCCESS:
        return "stripe_microdeposit"
      case STRIPE_CONNECTIONS_INSTANT_SUCCESS:
        return "stripe_connections_instant"
      case STRIPE_CONNECTIONS_MICRODEPOSIT_SUCCESS:
        return "stripe_connections_microdeposit"
      default:
        return null
    }
  }

  renderChooseStrategy = () => {
    const {
      achAllowed,
      paymentMethods,
      person: { account_center_id, email_address, logged_in },
      loginRedirectUrl,
    } = this.props
    const handleSelectStoredPaymentMethod = (id) => () => this.chooseStoredPaymentMethod(id)
    const cardGivingLevel = capitalize(this.getCardGivingLevelText())
    const hasStoredPaymentMethods = paymentMethods.length > 0

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        {!logged_in ? (
          <div>
            <div className="fs-4 mb-2">
              <LoginButton
                accountCenterId={account_center_id}
                emailAddress={email_address}
                redirectUrl={loginRedirectUrl}
                submitText={`Log in to access saved ${this.getPaymentMethodText()}`}
                className=""
              />
            </div>
          </div>
        ) : (
          hasStoredPaymentMethods && (
            <div>
              <PaymentMethodList>
                {paymentMethods.map(({ id, brand, method_type, type_and_last4 }) => (
                  <PaymentMethodChoice
                    key={id}
                    onSelect={handleSelectStoredPaymentMethod(id)}
                    icon={this.getPaymentMethodIcon({ brand, method_type })}
                    primary={type_and_last4}
                  />
                ))}
              </PaymentMethodList>
            </div>
          )
        )}
        <PaymentMethodList>
          {this.props.acceptApplePay && this.state.applePaySupported && (
            <PaymentMethodChoice
              key="use apple pay"
              icon="apple-pay"
              onSelect={this.chooseApplePayStrategy}
              primary="Use Apple Pay"
            />
          )}
          {achAllowed && (
            <PaymentMethodChoice
              key="enter new ach"
              icon="bank"
              onSelect={this.chooseBankStrategy}
              primary={`Add bank account${this.userCanAddBankAccount() ? "" : " (requires login)"}`}
              href={!this.userCanAddBankAccount() && this.urlToSaveInProgressDonationAndLogin()}
            />
          )}
          <PaymentMethodChoice
            key="enter new card"
            onSelect={this.chooseStripeCardStrategy}
            icon="new-credit-card"
            primary={cardGivingLevel}
          />
        </PaymentMethodList>
        {this.renderReassuringMessage({ marginTop: false })}
      </div>
    )
  }

  renderChooseBankVerificationStrategy = () => {
    const stripeError = this.digState("stripe", "error", "message")
    const handleCancel = () => this.reset()
    const { selected, loading } = this.state

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <div>
          <PaymentMethodList>
            <PaymentMethodChoice
              key="enter new ach"
              icon="bank"
              onCancel={handleCancel}
              primary="Add bank account"
              selected={selected}
            />
          </PaymentMethodList>
        </div>
        <div
          className="action-drawer pt-0 px-2 pl-7@sm pl-2@iframe pl-2@modal"
          {...css({ borderRadius: "0 0 4px 4px" })}
        >
          <div className="mb-3 ml-4p">
            We'll need to verify your bank account before it can be used for giving.
          </div>

          <div className="d-f ai-c jc-c mb-1">
            <Ladda
              loading={loading}
              className="compact-btn btn"
              onClick={this.openStripeConnections}
              data-cy="find_payment_method_verify_account_button"
            >
              Verify your account
            </Ladda>
          </div>

          <div className="mt-3 ml-4p fs-4">
            <ErrorMessage className="mb-0">{stripeError}</ErrorMessage>
          </div>
        </div>

        {this.renderReassuringMessage()}
      </div>
    )
  }

  renderStripeConnectionsInstantSuccess = () => {
    const { payment_method } = this.state.stripe.response.setupIntent
    const handleCancel = () => this.reset()
    const { selected } = this.state

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <div className="p-r">
          <PaymentMethodList>
            <PaymentMethodChoice
              icon="ach"
              primary={payment_method.us_bank_account.bank_name}
              secondary={` ${String.fromCharCode(8230)}${payment_method.us_bank_account.last4}`}
              onCancel={handleCancel}
              selected={selected}
            />
          </PaymentMethodList>

          <div className="action-drawer pt-0" {...css({ borderRadius: "0 0 3px 3px" })}>
            <div className="success-alert alert mb-0 ml-4 p-1">
              <span className="c-emerald p-r t-2p mr-4p">
                <Icon symbol="general#check" />
              </span>
              Verified
            </div>
          </div>
        </div>

        {this.props.children({
          paymentMethod: this.getPaymentMethodForFormFields(),
          requestToken: this.handleStripeConnectionsSubmit,
          autoSubmit: true,
        })}

        {this.renderReassuringMessage()}
      </div>
    )
  }

  renderStripeConnectionsMicrodepositSuccess = () => {
    const { payment_method } = this.state.stripe.response.setupIntent
    const handleCancel = () => this.reset()
    const { loading, selected } = this.state

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <div>
          <PaymentMethodList>
            <PaymentMethodChoice
              icon="ach"
              primary={payment_method.us_bank_account.bank_name}
              secondary={` ${String.fromCharCode(8230)}${payment_method.us_bank_account.last4}`}
              onCancel={handleCancel}
              selected={selected}
            />
          </PaymentMethodList>

          <div
            className="action-drawer pt-0 pl-2 pl-7@sm pt-1@modal pl-2@modal"
            {...css({ marginTop: -2 })}
          >
            <div className="mb-2 ml-4p">
              You will receive {I18n.t("ACH")} micro-deposits and will then need to verify them.
            </div>
          </div>

          {this.props.children({
            requestToken: this.handleStripeConnectionsSubmit,
            loading,
            paymentMethod: this.getPaymentMethodForFormFields(),
            submitUnverifiedPaymentMethod: true,
            verificationMethod: "stripe_connections_microdeposit",
          })}

          {this.renderReassuringMessage()}
        </div>
      </div>
    )
  }

  renderReassuringMessage = () => {
    const classes = classNames("d-f ai-fs mb-2 mt-3 fs-5 c-tint3 p-r")

    return (
      <div className={classes}>
        <span className="pr-4p">
          <Icon symbol="general#lock" aria-hidden />
        </span>
        <em>
          Payment information is TLS encrypted and stored at{" "}
          <a
            href="//stripe.com/"
            className="fw-600"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex="0"
          >
            Stripe
          </a>{" "}
          - a Level 1 PCI compliant payment processor.
          {this.props.achAllowed && (
            <span>
              {" "}
              Bank verification powered by{" "}
              <a
                href="//stripe.com"
                className="fw-600"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex="0"
              >
                Stripe
              </a>
              .
            </span>
          )}
        </em>
      </div>
    )
  }

  renderStripeBankInProgress = () => {
    const { account_holder_name, account_holder_type, account_number, routing_number } =
      this.state.stripe.input
    const error = this.userFriendlyError(this.digState("stripe", "response", "error", "message"))
    const { loading, selected } = this.state
    const setStripeField = this.setStripeField

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <div>
          <PaymentMethodList>
            <PaymentMethodChoice
              key="enter new ach"
              icon="bank"
              onCancel={this.reset}
              primary="Add bank account"
              selected={selected}
            />
          </PaymentMethodList>

          <div
            className="action-drawer pt-0 pl-2 pl-7@sm pt-1@modal pl-2@modal"
            {...css({ marginTop: -2 })}
          >
            <div className="mb-2 ml-4p">To get started, we need to verify your bank account.</div>
            <div className="fs-4 mb-2 ml-4p">
              With this information, we'll initiate the process and send you instructions by email.
              After verification is completed you'll be able to donate with this bank account.{" "}
              {!this.shouldShowTestUI() && (
                <span className="c-ruby">You will not be able to donate today.</span>
              )}
            </div>
            <div>
              <div className="d-f@sm f_1 mb-2">
                <div className="pb-1 pb-0@sm pr-1@sm">
                  <label
                    className="label fs-4 fw-400 c-gray_800"
                    htmlFor="bank_account_verification_legal_name"
                  >
                    Account Holder
                  </label>
                  <input
                    type="text"
                    id="bank_account_verification_legal_name"
                    placeholder="Legal name"
                    value={account_holder_name}
                    onChange={setStripeField(STRIPE_BANK_IN_PROGRESS, "account_holder_name")}
                  />
                </div>
                <div className="pl-1@sm">
                  <label
                    className="label fs-4 fw-400 c-gray_800"
                    htmlFor="bank_account_verification_account_holder_type"
                  >
                    Account Type
                  </label>
                  <select
                    className="select"
                    id="bank_account_verification_account_holder_type"
                    value={account_holder_type}
                    onChange={setStripeField(STRIPE_BANK_IN_PROGRESS, "account_holder_type")}
                  >
                    <option key="individual" value="individual">
                      Individual
                    </option>
                    <option key="company" value="company">
                      Company
                    </option>
                  </select>
                </div>
              </div>
              <div className="d-f@sm f_1">
                <div className="pb-1 pb-0@sm pr-1@sm">
                  <label
                    className="label fs-4 fw-400 c-gray_800"
                    htmlFor="bank_account_verification_routing_number"
                  >
                    Routing Number
                  </label>
                  <input
                    autoFocus
                    type="tel"
                    id="bank_account_verification_routing_number"
                    value={routing_number}
                    onChange={setStripeField(STRIPE_BANK_IN_PROGRESS, "routing_number")}
                  />
                  {this.shouldShowTestUI() && (
                    <div className="alert info-alert py-1 mt-4p">
                      <span className="fw-400 fs-5 pr-4p">USE:</span>
                      <span className="fw-600">110000000</span>
                    </div>
                  )}
                </div>
                <div className="pl-1@sm">
                  <label
                    className="label fs-4 fw-400 c-gray_800"
                    htmlFor="bank_account_verification_account_number"
                  >
                    Account Number
                  </label>
                  <input
                    type="tel"
                    id="bank_account_verification_account_number"
                    value={account_number}
                    onChange={setStripeField(STRIPE_BANK_IN_PROGRESS, "account_number")}
                  />
                  {this.shouldShowTestUI() && (
                    <div className="alert info-alert py-1 mt-4p">
                      <span className="fw-400 fs-5 pr-4p">USE:</span>
                      <span className="fw-600">000123456789</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ErrorMessage>{error}</ErrorMessage>

          {this.props.children({
            requestToken: this.handleStripeBankSubmit,
            loading,
            paymentMethod: this.getPaymentMethodForFormFields(),
            submitUnverifiedPaymentMethod: true,
            verificationMethod: "stripe_microdeposit",
          })}

          {this.renderReassuringMessage()}
        </div>
      </div>
    )
  }

  renderStripeCardInProgress = () => {
    const error = this.digState("stripe", "response", "error", "message")
    const handleCancel = () => this.reset()
    const { loading, selected } = this.state
    const primaryText = capitalize(this.getCardGivingLevelText())

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <div>
          <PaymentMethodList>
            <PaymentMethodChoice
              key="enter new card"
              icon="new-credit-card"
              onCancel={handleCancel}
              primary={primaryText}
              selected={selected}
            />
          </PaymentMethodList>

          <div
            className="action-drawer px-2"
            {...css({
              borderRadius: "0 0 4px 4px",
              marginTop: -2,
              paddingTop: 0,
              paddingBottom: 12,
            })}
          >
            {this.shouldShowTestUI() && <SelectTestCard />}
            <form
              onSubmit={(e) => e.preventDefault()}
              className="ml-0 ml-6@sm ml-0@iframe ml-0@modal"
              data-cy="find_payment_method_stripe_form"
              style={{
                backgroundColor: colors.tint10,
                borderRadius: 4,
                border: `1px solid ${colors.tint5}`,
                boxShadow: `inset 0 1px 2px ${colors.tint9}`,
                padding: 8,
              }}
            >
              <div
                ref={(e) => {
                  this.stripeElementsMountPoint = e
                }}
              />
            </form>
            <ErrorMessage className="ml-6@sm mb-0">{error}</ErrorMessage>
          </div>
        </div>

        {this.props.children({
          requestToken: this.handleStripeCardSubmit,
          loading,
          paymentMethod: this.getPaymentMethodForFormFields(),
        })}

        {this.renderReassuringMessage()}
      </div>
    )
  }

  renderApplePayStrategy = () => {
    const handleCancel = () => this.reset()
    const { selected } = this.state

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <div>
          <PaymentMethodList>
            <PaymentMethodChoice
              key="enter new card"
              icon="apple-pay"
              onCancel={handleCancel}
              primary="Use Apple Pay"
              selected={selected}
            />
          </PaymentMethodList>
          <div>
            <ApplePayButton
              onSuccess={this.handleApplePaySuccess}
              country={this.props.country}
              currency={this.props.currency}
              feeCoverageAllowed={this.props.feeCoverageAllowed}
              isCoveringFee={this.props.isCoveringFee}
              onChangeCoveringFee={this.props.onChangeCoveringFee}
              {...this.props.applePay}
            />
            <div style={{ display: "none" }}>
              {this.props.children({
                paymentMethod: this.getPaymentMethodForFormFields(),
                requestToken: noop,
              })}
            </div>
          </div>

          {this.renderReassuringMessage({ marginTop: false })}
        </div>
      </div>
    )
  }

  renderUsingStoredPaymentMethod = () => {
    const id = this.state.stored_payment_method_id
    const storedPaymentMethod = this.getStoredPaymentMethod(id)
    const { brand, method_type, last4, apple_pay } = storedPaymentMethod
    const handleCancel = () => this.reset()
    const getIcon = this.getPaymentMethodIcon
    const { selected } = this.state

    return (
      <div>
        <h3 className="h4 mb-1">Select payment method</h3>
        <PaymentMethodList>
          <PaymentMethodChoice
            icon={getIcon({ brand, method_type })}
            primary={brand}
            secondary={` ${String.fromCharCode(8230)}${last4}${apple_pay ? " (Apple Pay)" : ""}`}
            onCancel={handleCancel}
            selected={selected}
          />
        </PaymentMethodList>

        {this.props.children({
          paymentMethod: this.getPaymentMethodForFormFields(),
          requestToken: noop,
        })}

        {this.renderReassuringMessage()}
      </div>
    )
  }

  renderUnhandledStep = () => {
    const handleClick = () => {
      this.reset()
    }

    return (
      <div className="mt-1">
        <ErrorMessage>Something went wrong. Please try again.</ErrorMessage>
        <button className="btn mt-3" onClick={handleClick}>
          Try again?
        </button>
      </div>
    )
  }

  render() {
    switch (this.state.step) {
      case CHOOSE_STRATEGY:
        return this.renderChooseStrategy()
      case STRIPE_APPLE_PAY_IN_PROGRESS:
      case STRIPE_APPLE_PAY_SUCCESS:
        return this.renderApplePayStrategy()
      case CHOOSE_BANK_VERIFICATION_STRATEGY:
      case STRIPE_CONNECTIONS_FAILURE:
        return this.renderChooseBankVerificationStrategy()
      case STRIPE_BANK_IN_PROGRESS:
      case STRIPE_BANK_FAILURE:
      case STRIPE_BANK_SUCCESS:
        return this.renderStripeBankInProgress()
      case STRIPE_CONNECTIONS_INSTANT_SUCCESS:
      case STRIPE_CONNECTIONS_INSTANT_CONFIRMED:
        return this.renderStripeConnectionsInstantSuccess()
      case STRIPE_CONNECTIONS_MICRODEPOSIT_SUCCESS:
      case STRIPE_CONNECTIONS_MICRODEPOSIT_CONFIRMED:
        return this.renderStripeConnectionsMicrodepositSuccess()
      case STRIPE_CARD_IN_PROGRESS:
      case STRIPE_CARD_FAILURE:
      case STRIPE_CARD_SUCCESS:
        return this.renderStripeCardInProgress()
      case USING_STORED_PAYMENT_METHOD:
        return this.renderUsingStoredPaymentMethod()
      default:
        return this.renderUnhandledStep()
    }
  }
}

export { STRIPE_BANK_SUCCESS, STRIPE_CARD_SUCCESS, USING_STORED_PAYMENT_METHOD, UNRESOLVED }
