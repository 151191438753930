let nondeterministicRedirectLocation

export function run(configurationUrl) {
  nondeterministicRedirectLocation = configurationUrl
}

export function nondeterministicRedirect({ href, responseStatus }) {
  const query = jQuery.param({ href, response_status: responseStatus })
  window.location = `${nondeterministicRedirectLocation}?${query}`
}
