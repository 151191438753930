import React, { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import appConfig from "shared/runners/app_config"
import { func, node } from "prop-types"

const propTypes = {
  onSubmit: func.isRequired,
  children: node.isRequired,
  Form: func.isRequired,
}

CaptchaForm.propTypes = propTypes

export default function CaptchaForm(props) {
  const Form = props.Form || "form"

  const RealForm = appConfig.recaptcha.disabled ? NoCaptchaForm : UseCaptchaForm

  return <RealForm {...props} Form={Form} />
}

UseCaptchaForm.propTypes = propTypes

function UseCaptchaForm({ onSubmit, children, Form, ...rest }) {
  const recaptchaRef = useRef(null)
  const eventRef = useRef(null)
  const [hasEverPassed, setHasEverPassed] = useState(false)

  function executeRecaptcha(event) {
    event.preventDefault()
    event.stopPropagation()

    recaptchaRef.current.execute()

    eventRef.current = event
  }

  function handleCaptchaSuccess(token) {
    setHasEverPassed(true)
    if (token === "") {
      window.Bugsnag.notifyException(new Error("reCAPTCHA token is blank"))
    }
    onSubmit(eventRef.current)
  }

  function handleExpired() {
    window.Bugsnag.notifyException(new Error("reCAPTCHA expired"))
  }

  function handleError() {
    window.Bugsnag.notifyException(new Error("reCAPTCHA errored"))
  }

  const handleSubmit = hasEverPassed ? onSubmit : executeRecaptcha

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      {children}

      <ReCAPTCHA
        sitekey={appConfig.recaptcha.site_key}
        size="invisible"
        ref={recaptchaRef}
        onChange={handleCaptchaSuccess}
        onExpired={handleExpired}
        onErrored={handleError}
      />
    </Form>
  )
}

NoCaptchaForm.propTypes = propTypes

function NoCaptchaForm({ onSubmit, children, Form, ...rest }) {
  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    onSubmit(event)
  }

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      {children}
    </Form>
  )
}
