import React from "react"
import { number, bool, func, string } from "prop-types"
import cx from "classnames"

export default class DonationStatusProgressBar extends React.Component {
  static propTypes = {
    maxTime: number.isRequired,
    totalTicks: number,
    forceFinish: bool.isRequired,
    onHide: func.isRequired,
    className: string,
  }

  static defaultProps = {
    totalTicks: 40,
    className: "",
  }

  state = {
    ticks: 0,
    progress: 0,
    hide: false,
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, this.tickTime)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forceFinish && !this.props.forceFinish) {
      this.finish()
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.progress < 1 && this.state.progress >= 1) {
      this.finish()
    }

    if (!prevState.hide && this.state.hide) {
      this.props.onHide()
    }
  }

  componentWillUnmount() {
    this.clearInterval()
  }

  clearInterval = () => {
    this.interval && clearInterval(this.interval)
  }

  finish = () => {
    this.clearInterval()

    this.setState({ progress: 1 }, () => {
      setTimeout(() => this.setState({ hide: true }), 750)
    })
  }

  tick = () => {
    setTimeout(
      () =>
        this.setState(({ ticks, progress }) => {
          const nextTicks = ticks + 1
          let nextProgress = nextTicks / this.props.totalTicks

          if (nextProgress <= progress) {
            nextProgress = progress
          }

          return {
            ticks: nextTicks,
            progress: nextProgress,
          }
        }),
      Math.random() * this.tickTime
    )
  }

  get tickTime() {
    return this.props.maxTime / this.props.totalTicks
  }

  render() {
    const { progress, hide } = this.state
    const { className } = this.props

    let percentage = progress * 100

    if (percentage > 100) {
      percentage = 100
    }

    return (
      <div
        className={cx("progressBarContainer", className, {
          "anim--fadeOutUp": hide,
        })}
      >
        <div className="progressBar">
          <div className="progressBar--progress" style={{ width: `${percentage}%` }} />
        </div>
        <div className="progressBar--label anim--pulse">Processing...</div>
      </div>
    )
  }
}
