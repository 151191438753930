import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import $ from "jquery"
import cx from "classnames"
import colors from "church_center/utils/colors"

const DonationHistoryFilter = ({
  history_url,
  years,
  year = new Date().getFullYear(),
  donation_type = "donation",
  show_in_kind_donations = false,
  show_tax_deductible_only_filter = false,
}) => {
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedDonationType, setSelectedDonationType] = useState(donation_type)
  const [taxDeductibleOnlyValue, setTaxDeductibleOnlyValue] = useState(false)

  useEffect(() => {
    $.ajax({
      url: history_url,
      data: {
        year: selectedYear,
        donation_type: selectedDonationType,
        tax_deductible_only: taxDeductibleOnlyValue,
      },
      dataType: "script",
    })
  }, [history_url, selectedDonationType, taxDeductibleOnlyValue, selectedYear])

  const handleYearChange = ({ target: { value } }) => {
    setSelectedYear(value)
  }

  const handleTypeChange = ({ target: { value } }) => {
    setSelectedDonationType(value)
    setTaxDeductibleOnlyValue(false)
  }

  const handleTaxDeductibleOnlyValueChange = ({ target: { checked } }) => {
    setTaxDeductibleOnlyValue(checked)
  }

  const handleTaxDeductibleOnlyClick = () => {
    setTaxDeductibleOnlyValue(!taxDeductibleOnlyValue)
  }

  const getDownloadUrl = () => {
    return `${history_url}/download?year=${selectedYear}&donation_type=${selectedDonationType}&tax_deductible=${taxDeductibleOnlyValue}`
  }

  return (
    <>
      <div className="d-f jc-sb ai-c mb-2">
        <h2 className="h3">Donation history</h2>
        <a className="minor-btn secondary-btn btn" href={getDownloadUrl()} tabIndex="0">
          Download
        </a>
      </div>
      <div className="d-f ai-c jc-fs fw-w mb-2 g-1">
        <select
          className="select f-1"
          onChange={handleYearChange}
          value={selectedYear}
          id="donation_history_filter_select"
          name="donation_history_filter_select"
          style={{ width: "auto", height: 32 }}
        >
          {years.map((year_item) => (
            <option key={year_item} value={year_item}>
              {year_item}
            </option>
          ))}
        </select>
        {show_in_kind_donations && (
          <select
            className="select f-3"
            onChange={handleTypeChange}
            id="donation_history_type_select"
            name="donation_history_type_select"
            value={selectedDonationType}
            style={{ width: "auto", height: 32 }}
          >
            <option value="donation">Monetary donations</option>
            <option value="in_kind_donation">In-kind donations</option>
          </select>
        )}
        {show_tax_deductible_only_filter && (
          <div
            className={cx("br-4p d-f ai-c ws-nw px-1", {
              "v-h": selectedDonationType !== "donation",
            })}
            style={{
              border: `1px solid ${colors.tint5}`,
              height: 32,
              cursor: "pointer",
            }}
            onClick={handleTaxDeductibleOnlyClick}
          >
            <input
              className="checkbox"
              type="checkbox"
              checked={taxDeductibleOnlyValue}
              onChange={handleTaxDeductibleOnlyValueChange}
              name="donation_history_tax_deductible_only"
            />
            <label className="checkbox-label" htmlFor="donation_history_tax_deductible_only">
              Hide nondeductible donations
            </label>
          </div>
        )}
      </div>
    </>
  )
}

DonationHistoryFilter.propTypes = {
  history_url: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  year: PropTypes.number,
  donation_type: PropTypes.string,
  show_in_kind_donations: PropTypes.bool,
  show_tax_deductible_only_filter: PropTypes.bool,
}

export default DonationHistoryFilter
