import renderModal from "shared/utils/render_modal"
import jQuery from "jquery"

let currentId, apiUrl

function verifySessionMatch(data) {
  if (data.id !== currentId) {
    jQuery(document).on("click", "#automatic-logout-reload", reloadPage)

    renderModal(`
      <div class="modal">
        <div class="modal__header">
          <div class="modal-title">
            Automatic log out
          </div>
        </div>

        <div class="modal__body">
          This usually happens when you've logged in somewhere else (perhaps another browser tab).
        </div>

        <div class="modal__footer">
          <button class="sm-btn text-btn btn" type="button" id="automatic-logout-reload" tabindex="0">Reload page</button>
        </div>
      </div>
    `)
  }
}

function reloadPage() {
  window.location.reload()
}

function queryCurrentUser() {
  jQuery.get(apiUrl).done(verifySessionMatch)
}

function startMonitor() {
  window.setInterval(queryCurrentUser, 30000)
}

export function run(configuration) {
  apiUrl = configuration.church_center.who_am_i_url
  currentId = configuration.currentPerson.id

  if (currentId !== null) {
    jQuery(startMonitor)
  }
}
