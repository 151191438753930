import React from "react"
import { Menu, MenuPopover, MenuButton, MenuItems } from "@reach/menu-button"
import { positionRight } from "@reach/popover"
import styled from "@emotion/styled"
import Icon from "church_center/components/external_icon"
import { bool, string, shape } from "prop-types"

WaysToGive.propTypes = {
  current_org_name: string,
  church_center_app_is_enabled: bool,
  current_person_text_to_give_is_connected: bool,
  text_to_give_is_enabled: bool,
  app_download_urls: shape({
    church_center_app_android_download_url: string,
    church_center_app_ios_download_url: string,
  }),
  routes: shape({
    church_center_root_url: string,
    church_center_profile_text_to_give_url: string,
  }),
}

const StyledMenuPopover = styled(MenuPopover)`
  padding: 0;
  margin-top: 4px;
  max-width: 280px;
  background: var(--color-tint10);
`

const StyledMenuItem = styled.div`
  border-bottom: 1px solid var(--color-tint6);

  &:last-child {
    border-bottom: none;
  }

  div {
    display: inline-flex;
  }

  h3 {
    font-size: 16px;
    color: var(--color-tint1);
    margin: 0;
  }

  p {
    font-size: 14px;
    color: var(--color-tint2);
  }
`

const StyledMenuLink = styled.a`
  display: inline-block;
  padding: 0;
  color: var(--color-brand);
  font-weight: 600;
  border-radius: 0;
  line-height: 1;

  &:hover {
    text-decoration: underline;
    color: var(--color-brand);
    background: none !important;
    outline: none !important;
  }

  &[data-selected] {
    outline-width: 2px;
    outline-color: var(--color-topaz);
    outline-style: solid;
    outline-offset: 2px;
    background: none !important;
    color: var(--color-brand) !important;
  }
`

const StyledMenuButton = styled.a`
  display: inline-flex;
  color: var(--color-tint10) !important;
  background: linear-gradient(135deg, var(--color-topaz) 0%, var(--color-emerald) 100%) !important;
  border-radius: 30px !important;

  &:hover {
    outline: none !important;
    color: var(--color-tint10) !important;
  }

  &[data-selected] {
    outline-width: 2px;
    outline-color: var(--color-topaz);
    outline-style: solid;
    outline-offset: 2px;
  }
`

export default function WaysToGive({
  current_org_name,
  church_center_app_is_enabled,
  current_person_text_to_give_is_connected,
  text_to_give_is_enabled,
  app_download_urls,
  routes,
}) {
  if (church_center_app_is_enabled || text_to_give_is_enabled) {
    return (
      <Menu>
        <MenuButton style={{ marginLeft: "auto" }} className="minor-btn secondary-btn btn">
          Ways to give
          <span className="fs-6 pl-4p dropdown-trigger__icon">
            <Icon symbol="general#down-chevron" aria-hidden style={{ width: 9, height: 9 }} />
          </span>
        </MenuButton>
        <StyledMenuPopover position={positionRight}>
          <MenuItems>
            <StyledMenuItem className="p-2 d-f ai-c jc-sb" role="menuitem">
              <div className="h3">On the Web</div>
              <StyledMenuButton href={routes.church_center_root_url} className="btn minor-btn">
                Give now
              </StyledMenuButton>
            </StyledMenuItem>

            {church_center_app_is_enabled && (
              <StyledMenuItem className="p-2" role="menuitem">
                <div className="h3">On the mobile app</div>
                <p className="m-0">
                  With the free Church Center app, you can donate, view your past donations, and
                  more from your mobile device. Get the app for{" "}
                  <StyledMenuLink
                    href={app_download_urls.church_center_app_android_download_url}
                    target="_blank"
                  >
                    Android
                  </StyledMenuLink>{" "}
                  or{" "}
                  <StyledMenuLink
                    href={app_download_urls.church_center_app_ios_download_url}
                    target="_blank"
                  >
                    iOS
                  </StyledMenuLink>
                  .
                </p>
              </StyledMenuItem>
            )}

            {text_to_give_is_enabled && (
              <StyledMenuItem className="p-2" role="menuitem">
                <h3>Text2Give</h3>
                <p className="mb-1">Donate to {current_org_name} by sending a text message.</p>
                <StyledMenuLink href={routes.church_center_profile_text_to_give_url}>
                  {current_person_text_to_give_is_connected
                    ? "Manage your settings"
                    : "Get started"}
                </StyledMenuLink>
              </StyledMenuItem>
            )}
          </MenuItems>
        </StyledMenuPopover>
      </Menu>
    )
  } else {
    return (
      <a href={routes.church_center_root_url} className="btn minor-btn primary-btn" tabIndex="0">
        Give now
      </a>
    )
  }
}
