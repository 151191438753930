import React from "react"
import { string, func, bool } from "prop-types"
import DonationStatusProgressBar from "church_center/components/donation_status_progress_bar"
import LongPoller from "church_center/components/long_poller"
import {
  DonationStatusIconSuccess,
  DonationStatusIconError,
} from "church_center/components/shared/donation_status_icons"

export default class BackgroundDonationStatus extends React.Component {
  static propTypes = {
    __html: string.isRequired,
    sgid: string.isRequired,
    person_is_mailable: bool.isRequired,
    onTryAgain: func.isRequired,
  }

  state = { progressVisible: true }

  componentDidMount() {
    this.churchCenterNavWrapper.classList.add("hideAll")
  }

  componentWillUnmount() {
    this.churchCenterNavWrapper.classList.remove("hideAll")
  }

  handleProgressHide = () => {
    this.setState({ progressVisible: false })
    this.churchCenterNavWrapper.classList.remove("hideAll")
  }

  isDonePolling = (data) => data && data.payment_status

  get churchCenterNavWrapper() {
    const element = document.querySelector(".churchcenter-nav-wrapper")

    if (!element) {
      console.error("BackgroundDonationStatus expects to find a church center nav wrapper")

      return document.createElement("div")
    } else {
      return element
    }
  }

  renderGiveUp = () => {
    const { __html } = this.props
    return (
      <div>
        <div className="d-f ai-c jc-c mb-3">
          <div className="d-f ai-c">
            <DonationStatusIconSuccess />
            <h1 className="pl-1">Donation Processing</h1>
          </div>
        </div>

        <div data-cy="background_donation_status_description">
          Your donation has been received! We’re processing your donation and will email a receipt
          for your records.
          {this.renderDonorInfo()}
        </div>
      </div>
    )
  }

  renderLongPollingData = (data) => {
    if (data.payment_status === "succeeded") {
      return (
        <div>
          <div className="d-f ai-c jc-c mb-3">
            <div className="d-f ai-c">
              <DonationStatusIconSuccess />
              <h1 className="pl-1">Thank you!</h1>
            </div>
          </div>

          <div data-cy="background_donation_status_description">
            We’re processing your donation and will email a receipt for your records.
            {this.renderDonorInfo()}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="d-f ai-c jc-c mb-3 c-ruby">
            <div className="d-f ai-c">
              <DonationStatusIconError />
              <h1 className="pl-1">Error</h1>
            </div>
          </div>

          <h3 data-cy="background_donation_status_description">{data.message}</h3>
          <p className="mt-1">
            <button className="btn mt-3" onClick={this.props.onTryAgain}>
              Try Again
            </button>
          </p>
        </div>
      )
    }
  }

  renderDonorInfo = () => {
    const { person_is_mailable, __html } = this.props

    return (
      <div className="mt-2">
        {!person_is_mailable && <span>Please add a mailing address to complete your profile.</span>}
        <div dangerouslySetInnerHTML={{ __html }} />
      </div>
    )
  }

  render() {
    const { sgid, __html } = this.props
    const { progressVisible } = this.state
    const maxTime = 10 * 1000

    return (
      <LongPoller
        url={`/giving/donations/${sgid}/status`}
        isDonePolling={this.isDonePolling}
        giveUpAfter={maxTime}
      >
        {({ data, didGiveUp }) => (
          <div className="ta-c px-3 px-5@md">
            <DonationStatusProgressBar
              maxTime={maxTime * 1.1}
              forceFinish={!!(data || didGiveUp)}
              onHide={this.handleProgressHide}
            />

            {!progressVisible && (
              <div className="anim--fadeInUp p-r mb-3">
                {data ? this.renderLongPollingData(data) : didGiveUp && this.renderGiveUp()}
              </div>
            )}
          </div>
        )}
      </LongPoller>
    )
  }
}
