import ApiBase from "shared/models/api_base"

class OccurrenceApi extends ApiBase {
  index(data) {
    return this.request("/giving/api/v1/occurrences.json", { data })
  }

  startDates(data) {
    return this.request("/giving/api/v1/occurrences/start_dates.json", { data })
  }
}

export default new OccurrenceApi()
