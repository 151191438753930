import "church_center/utils/assign_ordered_boot_globals"
import "core-js/stable"
import "regenerator-runtime/runtime"
import "whatwg-fetch"
import * as Components from "church_center/components"
import assignGlobals from "shared/utils/assign_globals"
import * as bugsnagMockLogger from "shared/runners/bugsnag_mock_logger"
import closeModal from "shared/utils/close_modal"
import * as configure from "church_center/runners/configure"
import * as configureBugsnag from "shared/runners/configure_bugsnag"
import * as datePicker from "shared/runners/datepicker"
import * as helperShelf from "shared/runners/helper_shelf"
import * as iframeBehaviors from "church_center/runners/iframe_behaviors"
import * as modalScrollFix from "church_center/runners/modal_scroll_fix"
import renderModal from "shared/utils/render_modal"
import * as sessionTools from "church_center/runners/session_tools"
import * as turboforms from "shared/runners/turboforms"
import * as ujsDataRemoteErrors from "shared/runners/ujs_data_remote_errors"
import * as ujsProgressBar from "shared/runners/ujs_progress_bar"
import * as ujsSweetAlert from "shared/runners/ujs_sweet_alert"
import { alert } from "shared/runners/sweetalert"
import { run as runSweetAlert } from "shared/runners/sweetalert"
import { run as runFixedTooltip } from "shared/runners/fixed_tooltip"
import { polyfill as polyfillPromises } from "es6-promise"

assignGlobals({
  Components,
  ChurchCenter: {
    alert,
    closeModal,
    renderModal,
    run(configuration) {
      polyfillPromises()
      configure.run(configuration)

      bugsnagMockLogger.run()
      configureBugsnag.run()
      datePicker.run()
      helperShelf.run()
      iframeBehaviors.run(configuration)
      modalScrollFix.run()
      runSweetAlert()
      runFixedTooltip()
      sessionTools.run(configuration)
      turboforms.run()
      ujsDataRemoteErrors.run()
      ujsProgressBar.run()
      ujsSweetAlert.run()
    },
  },
})
