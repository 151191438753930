import React from "react"
import { shape, oneOfType, string, number, bool } from "prop-types"
import { HashFields, HiddenField } from "react-rails-form-helpers"

PersonFormFields.propTypes = {
  person: shape({
    account_center_id: oneOfType([string, number]).isRequired,
    email_address: string.isRequired,
    logged_in: bool,
  }).isRequired,
}

function PersonFormFields({ person: { account_center_id, email_address, logged_in } }) {
  if (logged_in) {
    return <noscript />
  }

  return (
    <HashFields name="person">
      <HiddenField name="account_center_id" value={account_center_id} />
      <HiddenField name="email_address" value={email_address} />
    </HashFields>
  )
}

export default PersonFormFields
