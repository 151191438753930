import React from "react"
import { string, shape, number } from "prop-types"
import DonationStatusProgressBar from "church_center/components/donation_status_progress_bar"
import LongPoller from "church_center/components/long_poller"
import {
  DonationStatusIconSuccess,
  DonationStatusIconError,
} from "church_center/components/shared/donation_status_icons"

export default class BackgroundDonationStatusForCCApp extends React.Component {
  static propTypes = {
    sgid: string.isRequired,
    id: number.isRequired,
    payment_status: string,
    routes: shape({
      successful_donation: string.isRequired,
      processing_donation: string.isRequired,
      failed_donation: string.isRequired,
    }).isRequired,
  }

  state = { progressVisible: true }

  handleProgressHide = () => {
    this.setState({ progressVisible: false })
  }

  isDonePolling = (data) => data && data.payment_status

  renderGiveUp = () => {
    return (
      <div className="ta-c p-3 px-5@md">
        <div className="d-f ai-c jc-c mb-2">
          <div className="d-f ai-c jc-sb">
            <DonationStatusIconSuccess />
            <h1 className="pl-1">Donation Processing</h1>
          </div>
        </div>

        <div>
          <p className="mb-3">
            Your donation has been received! We’re processing your donation and will email a receipt
            for your records.
          </p>
          <a
            href={buildURL(this.props.routes.processing_donation, {
              id: this.props.id,
            })}
            className="btn secondary-btn"
            tabIndex="0"
          >
            Back to Church Center
          </a>
        </div>
      </div>
    )
  }

  renderLongPollingData = (data) => {
    if (data.payment_status === "succeeded") {
      return (
        <div className="ta-c p-3 px-5@md">
          <div className="d-f ai-c jc-c mb-2">
            <div className="d-f ai-c jc-sb">
              <DonationStatusIconSuccess />
              <h1 className="pl-1">Thank you!</h1>
            </div>
          </div>

          <div>
            <p className="mb-3">
              We’re processing your donation and will email a receipt for your records.
            </p>
            <a
              href={buildURL(this.props.routes.successful_donation, {
                id: this.props.id,
              })}
              className="btn secondary-btn"
              tabIndex="0"
            >
              Back to Church Center
            </a>
          </div>
        </div>
      )
    } else {
      return (
        <div className="ta-c p-3 px-5@md">
          <div className="d-f ai-c jc-c mb-2">
            <div className="d-f ai-c jc-sb">
              <DonationStatusIconError />
              <h1 className="c-ruby pl-1">Error</h1>
            </div>
          </div>
          <h3 className="mb-3">{data.message}</h3>
          <a
            href={buildURL(this.props.routes.failed_donation, {
              id: this.props.id,
              message: data.message,
            })}
            className="btn secondary-btn"
            tabIndex="0"
          >
            Back to Church Center
          </a>
        </div>
      )
    }
  }

  render() {
    const { sgid, payment_status } = this.props
    const { progressVisible } = this.state
    const maxTime = 10 * 1000

    if (payment_status) {
      return this.renderLongPollingData({ payment_status })
    }

    return (
      <LongPoller
        url={`/giving/donations/${sgid}/status`}
        isDonePolling={this.isDonePolling}
        giveUpAfter={maxTime}
      >
        {({ data, didGiveUp }) => (
          <div>
            <DonationStatusProgressBar
              className="progressBar--ccapp"
              maxTime={maxTime * 1.1}
              forceFinish={!!(data || didGiveUp)}
              onHide={this.handleProgressHide}
            />

            {!progressVisible && (
              <div className="anim--fadeInUp p-r" style={{ marginTop: "-40px" }}>
                {data ? this.renderLongPollingData(data) : didGiveUp && this.renderGiveUp()}
              </div>
            )}
          </div>
        )}
      </LongPoller>
    )
  }
}

function buildURL(url, params = null) {
  if (params) {
    return `${url}?${jQuery.param(params)}`
  } else {
    return url
  }
}
