import React, { Fragment } from "react"
import { arrayOf, shape, string } from "prop-types"
import { Menu, MenuList, MenuButton, MenuLink } from "@reach/menu-button"

GivingProfileSidebar.propTypes = {
  items: arrayOf(
    shape({
      label: string.isRequired,
      slug: string.isRequired,
      url: string.isRequired,
    })
  ),
  active_item_slug: string.isRequired,
}

export default function GivingProfileSidebar({ items, active_item_slug }) {
  const activeRouteName = items.find((item) => item.slug === active_item_slug).label

  return (
    <Fragment>
      <nav className="sub-navigation mr-4 d-n d-b@md">
        {items.map((item, index) => (
          <a
            className={`sub-navigation__link ${
              active_item_slug === item.slug ? "is-active-link" : null
            }`}
            href={item.url}
            key={index}
            tabIndex="0"
          >
            {item.label}
          </a>
        ))}
      </nav>

      <div className="d-n@md mb-3">
        <Menu>
          <MenuButton className="select ta-l">{activeRouteName}</MenuButton>
          <MenuList>
            {items.map((item, index) => (
              <MenuLink as="a" href={item.url} key={index}>
                {item.label}
              </MenuLink>
            ))}
          </MenuList>
        </Menu>
      </div>
    </Fragment>
  )
}
