import React from "react"
import classNames from "classnames"
import { node, string } from "prop-types"

export default class ErrorMessage extends React.Component {
  static propTypes = {
    children: node,
    className: string,
  }

  render() {
    const { ...rest } = this.props
    const className = classNames("error-alert alert p-1 mt-1", rest.className)

    if (React.Children.count(this.props.children) === 0) {
      return null
    }

    return (
      <div {...rest} className={className}>
        <div dangerouslySetInnerHTML={{ __html: this.props.children }} />
      </div>
    )
  }
}
