import React from "react"
import Ladda from "shared/components/ladda"
import PersonFormFields from "church_center/components/donation_form/person_form_fields"
import PaymentMethodFormFields from "church_center/components/donation_form/payment_method_form_fields"
import MoneyInput from "shared/components/money_input"
import * as coverTheFee from "church_center/utils/cover_the_fee"
import { designationsMinimalString, scheduleFrequency } from "church_center/utils/designations"
import { ArrayFields, FormFor, HashFields, HiddenField } from "react-rails-form-helpers"
import { ccoPerson } from "shared/utils/prop_types"
import { arrayOf, bool, func, object, string } from "prop-types"
import findPaymentMethodTokenResolver from "church_center/components/find_payment_method/find_payment_method_token_resolver"

SubmitRecurringDonationForm.propTypes = {
  designations: arrayOf(object).isRequired,
  loading: bool.isRequired,
  feeCoverageAllowed: bool.isRequired,
  isCoveringFee: bool.isRequired,
  onChangeCoveringFee: func.isRequired,
  onSubmit: func.isRequired,
  paymentMethod: object.isRequired,
  person: ccoPerson.isRequired,
  schedule: object,
  next_occurrence: string,
  url: string.isRequired,
}

function SubmitRecurringDonationForm({
  designations,
  loading,
  feeCoverageAllowed,
  isCoveringFee,
  onChangeCoveringFee,
  onSubmit,
  paymentMethod,
  person,
  schedule,
  next_occurrence,
  url,
}) {
  const { designationsCoveringFee, coveredFeeCents } = coverTheFee.calculate(
    designations,
    paymentMethod.paymentMethodType
  )
  const selectedDesignations = isCoveringFee ? designationsCoveringFee : designations
  const totalAmount = designationsMinimalString(selectedDesignations)
  const coveredFeeAmount = MoneyInput.minimalTextValue(coveredFeeCents / 100)
  const frequency = scheduleFrequency(schedule)

  return (
    <FormFor url={url} method="post" data-remote onSubmit={onSubmit}>
      <HashFields name="donation">
        <HiddenField name="schedule" value={JSON.stringify(schedule)} />
        {next_occurrence && <HiddenField name="next_occurrence" value={next_occurrence} />}
        <ArrayFields name="designations_attributes">
          {selectedDesignations.map(({ fund_id, amount }, index) => (
            <HashFields key={index}>
              <HiddenField name="fund_id" value={fund_id} />
              <HiddenField name="amount" value={amount} />
            </HashFields>
          ))}
        </ArrayFields>
        <HiddenField name="fee_covered" value={isCoveringFee} />
      </HashFields>

      <PersonFormFields person={person} />
      <PaymentMethodFormFields paymentMethod={paymentMethod} />

      {feeCoverageAllowed && paymentMethod.paymentMethodType && (
        <div className="d-b w-100%@iframe m-0a" style={{ margin: "0 auto" }}>
          <div className="fs-4 my-2">
            <input
              className="checkbox"
              id="covering_fee"
              type="checkbox"
              checked={isCoveringFee}
              onChange={(event) => {
                onChangeCoveringFee(event.target.checked)
              }}
            />
            <label className="checkbox-label" htmlFor="covering_fee">
              {`Add $${coveredFeeAmount} to cover the processing fee`}
            </label>
            {paymentMethod.paymentMethodType === "card" && (
              <div className="fs-5 c-tint3 pl-3">
                Note: {I18n.t("ACH")} bank transfers only cost $0.
                {coverTheFee.getFeeRates("bank_account").flat_cents}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-3 ta-c">
        <Ladda button loading={loading} className="btn">
          Start giving ${totalAmount} {frequency}
        </Ladda>
      </div>
    </FormFor>
  )
}

export default findPaymentMethodTokenResolver(SubmitRecurringDonationForm)
