import React from "react"
import { node } from "prop-types"

HorizontalRule.propTypes = {
  children: node,
}

export default function HorizontalRule({ children }) {
  const dividerStyle = {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "lightgray",
  }

  const hrStyle = {
    flexGrow: 1,
    margin: 0,
    border: "none",
    borderTop: "2px solid lightgray",
  }

  const spanStyle = {
    padding: "0 10px",
    fontWeight: "bold",
    color: "lightgray",
  }

  return (
    <div style={dividerStyle}>
      <hr style={hrStyle} />
      {children && <span style={spanStyle}>{children}</span>}
      <hr style={hrStyle} />
    </div>
  )
}
