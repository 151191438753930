import React from "react"
import { string, func, number } from "prop-types"

export default class LongPoller extends React.Component {
  static propTypes = {
    url: string.isRequired,
    children: func.isRequired,
    isDonePolling: func.isRequired,
    giveUpAfter: number.isRequired,
  }

  state = {
    data: null,
    shouldGiveUpAfterNextRequest: false,
    didGiveUp: false,
  }

  componentDidMount() {
    this.makeRequest()
    this.giveUpTimeout = setTimeout(
      () => this.setState({ shouldGiveUpAfterNextRequest: true }),
      this.props.giveUpAfter
    )
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
    this.giveUpTimeout && clearTimeout(this.giveUpTimeout)
  }

  handleSuccess = (data) => {
    this.setState({ data })
  }

  handleComplete = () => {
    const { shouldGiveUpAfterNextRequest } = this.state

    if (shouldGiveUpAfterNextRequest) {
      this.setState({ didGiveUp: true })
    } else {
      this.timeout = setTimeout(this.makeRequest, 1000)
    }
  }

  makeRequest = () => {
    if (this.props.isDonePolling(this.state.data)) return

    const { url } = this.props
    jQuery.ajax({
      url,
      dataType: "json",
      success: this.handleSuccess,
      complete: this.handleComplete,
    })
  }

  render() {
    return this.props.children({
      data: this.props.isDonePolling(this.state.data) && this.state.data,
      didGiveUp: this.state.didGiveUp,
    })
  }
}
