import React, { useEffect, useState } from "react"
import PersonBadge from "church_center/components/shared/person_badge"
import ErrorMessage from "church_center/components/shared/error_message"
import NewPersonForm from "church_center/components/find_person/new_person_form"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { ccoPerson } from "shared/utils/prop_types"
import { func, node } from "prop-types"

function serialize(form) {
  return jQuery(form)
    .serializeArray()
    .reduce((memo, item) => {
      memo[item.name] = item.value
      return memo
    }, {})
}

FindPerson.propTypes = {
  loggedInPerson: ccoPerson,
  onChange: func.isRequired,
  preCreateInformation: node,
}

export default function FindPerson({
  loggedInPerson = null,
  onChange = () => null,
  preCreateInformation = null,
}) {
  const [person, setPerson] = useState(loggedInPerson)
  const [ajaxError, setAjaxError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    person && onChange(person)
  }, [onChange, person])

  async function handleSubmitCreate(event) {
    const personParams = serialize(event.target, { hash: true })

    setLoading(true)
    setAjaxError(false)

    try {
      const json = await sessionApiClient.post(
        "/giving/api/v1/guests",
        {
          data: { type: "Guest", attributes: personParams },
        },
        { origin: window.location.origin }
      )

      const {
        data: { attributes },
      } = json

      const name = `${attributes.first_name} ${attributes.last_name}`.trim()

      setPerson({
        account_center_id: json.data.id,
        name,
        ...attributes,
      })
    } catch (_error) {
      setLoading(false)
      setAjaxError(true)
    }
  }

  return person ? (
    <div className="my-4">
      <h2 className="h4 mb-1">My information</h2>
      <PersonBadge person={person} />
    </div>
  ) : (
    <React.Fragment>
      <NewPersonForm
        loading={loading}
        onSubmit={handleSubmitCreate}
        preCreateInformation={preCreateInformation}
      />
      {ajaxError && (
        <div className="ta-c">
          <ErrorMessage className="w-100%">
            Oops! Something unexpected happened. Please try again.
          </ErrorMessage>
        </div>
      )}
    </React.Fragment>
  )
}
