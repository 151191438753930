import React, { useState } from "react"
import classNames from "classnames"
import { featureEnabled } from "shared/runners/flipper"

const currentYear = parseInt(new Date().getFullYear().toString().substr(-2), 10)

export default function SelectTestCard() {
  const [testPaymentType, setTestPaymentType] = useState("")

  const getTestPaymentDetails = (type) => {
    switch (type) {
      case "visa":
        return "4242 4242 4242 4242"
      case "visa-debit":
        return "4000 0566 5566 5556"
      case "canada-visa":
        return "4000 0012 4000 0000"
      case "mastercard":
        return "5555 5555 5555 4444"
      case "amex":
        return "3782 822463 10005"
      case "discover":
        return "6011 1111 1111 1117"
      case "usa-bank-account":
        return "Account: 000123456789, Routing: 110000000"
      case "aus-bank-account":
        return "Account: 000123456, BSB: 000-000"
      case "aus-visa":
        return "4000 0003 6000 0006"
      case "nz-visa":
        return "4000 0055 4000 0008"
      default:
        return null
    }
  }

  const isBankAccount = (type) => {
    return type.includes("bank-account")
  }

  const isAusOrNzAccount = (type) => {
    return type.includes("aus") || type.includes("nz")
  }

  return (
    <div
      className={classNames("pl-0 pl-0@iframe pl-0@modal", {
        "pl-6@sm": !featureEnabled("stripe_payment_element"),
      })}
    >
      <div className="alert info-alert">
        <div className="d-f fd-cr fd-r@sm ai-fs ai-c@sm fd-cr@iframe ai-fs@iframe fd-cr@modal ai-fs@modal">
          <div className="w-100% w-a@sm w-100%@iframe">
            <select className="select" onChange={(e) => setTestPaymentType(e.target.value)}>
              <option value="">Select a test payment method:</option>
              <optgroup label="USA Payment Methods">
                <option value="visa">Visa</option>
                <option value="visa-debit">Visa (debit)</option>
                <option value="mastercard">Mastercard</option>
                <option value="amex">American Express</option>
                <option value="discover">Discover</option>
                <option value="usa-bank-account">ACH - Bank Account</option>
              </optgroup>
              <optgroup label="CAN Payment Methods">
                <option value="canada-visa">Visa</option>
              </optgroup>
              <optgroup label="AUS Payment Methods">
                <option value="aus-visa">Visa</option>
                <option value="aus-bank-account">BECS - Bank Account</option>
              </optgroup>
              <optgroup label="NZ Payment Methods">
                <option value="nz-visa">Visa</option>
              </optgroup>
            </select>
          </div>

          <p className="d-ib ml-1@sm mb-1 mb-0@sm ml-0@iframe mb-1@iframe ml-0@modal mb-1@modal">
            (This account is in test mode)
          </p>
        </div>

        {testPaymentType !== "" && (
          <div className="px-4p pt-2 d-f fd-c fd-r@sm ai-c@sm">
            <span className="fw-400 fs-5 pr-4p mb-4p mb-0@sm d-n d-if@sm d-n@iframe-modal">
              USE:
            </span>

            <div className="d-f jc-sb f-1 fw-600 fs-5 fs-4@xs fs-5@iframe-modal">
              <div>{getTestPaymentDetails(testPaymentType)}</div>
              {!isBankAccount(testPaymentType) && (
                <div className="d-if d-f@iframe-modal f-1@iframe-modal jc-sb@iframe-modal">
                  <span className="ml-2@iframe-modal">01/{currentYear + 5}</span>
                  <span className="ml-1 ml-2@xs m-0@iframe-modal">
                    {testPaymentType === "amex" ? "1234" : "222"}
                  </span>
                  {!isAusOrNzAccount(testPaymentType) && (
                    <span className="ml-1 ml-2@xs m-0@iframe-modal">
                      {testPaymentType === "canada-visa" ? "M1A 0A0" : "92009"}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
