import React from "react"
import ErrorMessage from "church_center/components/shared/error_message"
import FindPerson from "church_center/components/find_person"
import FindPaymentMethod from "church_center/components/find_payment_method"
import SubmitActivationForm from "church_center/components/text_to_give_activation_form/submit_activation_form"
import { ccoPerson } from "shared/utils/prop_types"
import { designationsMinimalString } from "church_center/utils/designations"
import { arrayOf, oneOfType, string, shape, number, bool } from "prop-types"

export default class TextToGiveActivationForm extends React.Component {
  static propTypes = {
    display_incoming_phone_number: string.isRequired,
    phone_number: string.isRequired,
    designations: arrayOf(
      shape({
        name: string.isRequired,
        amount: oneOfType([string, number]).isRequired,
      })
    ),
    card_giving_level: string.isRequired,
    accept_apple_pay: bool.isRequired,
    ach_allowed: bool.isRequired,
    logged_in_person: ccoPerson,
    organization: shape({
      account_center_id: number.isRequired,
      country: string.isRequired,
      currency: string.isRequired,
      name: string.isRequired,
    }).isRequired,
    routes: shape({
      update: string.isRequired,
      self: string.isRequired,
    }),
    error: string,
  }

  static defaultProps = {
    error: null,
  }

  state = {
    loading: false,
    person: this.props.logged_in_person,
    acceptedToS: this.props.logged_in_person ? true : false,
  }

  handleChangePerson = (person) => {
    this.setState({ person, paymentMethod: null })
  }

  renderPreCreateInformation = () => (
    <React.Fragment>
      <div className="mt-2">
        <input
          type="checkbox"
          className="checkbox"
          onChange={(e) => this.setState({ acceptedToS: e.target.checked })}
          id="accept-tos"
          required
        />
        <label htmlFor="accept-tos" className="checkbox-label">
          I agree to the Text2Give{" "}
          <a
            href="http://planningcenter.com/terms#text-messaging"
            rel="noopener noreferrer"
            target="_blank"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.planningcenter.com/congregant-privacy"
            rel="noopener noreferrer"
            target="_blank"
          >
            privacy policy
          </a>
        </label>
      </div>
      <div className="sm-alert-info-alert alert c-tint2 py-1 px-4 fs-13 mt-3 mb-0 ta-c">
        By enrolling in Text2Give, you agree to receive transactional SMS messages from{" "}
        {this.props.display_incoming_phone_number} to{" "}
        <span className="ws-nw">{this.props.phone_number}</span>. At any time, you can text HELP for
        help or STOP to unsubscribe. Message and data rates may apply. Average of 2 messages per
        request.
      </div>
    </React.Fragment>
  )

  render() {
    const { designations, logged_in_person: loggedInPerson, error } = this.props
    const paymentMethods = loggedInPerson ? loggedInPerson.payment_methods : []

    const totalAmount = designationsMinimalString(designations)
    const applePay = {
      designations,
      description: "Future Text2Give donations",
      organizationName: this.props.organization.name,
    }

    return (
      <div>
        <FindPerson
          autoSelect
          autoFocus
          loggedInPerson={loggedInPerson}
          onChange={this.handleChangePerson}
          preCreateInformation={this.renderPreCreateInformation()}
        />
        {this.state.person && (
          <FindPaymentMethod
            acceptApplePay={this.props.accept_apple_pay}
            applePay={applePay}
            cardGivingLevel={this.props.card_giving_level}
            country={this.props.organization.country}
            currency={this.props.organization.currency}
            achAllowed={this.props.ach_allowed}
            organizationAccountCenterId={this.props.organization.account_center_id}
            paymentMethods={paymentMethods}
            person={this.state.person}
            loginRedirectUrl={this.props.routes.self}
            ttgSetup={true}
            newPaymentMethodUrl={this.props.routes.new_payment_method_url}
          >
            {({
              paymentMethod,
              requestToken,
              loading,
              submitUnverifiedPaymentMethod,
              verificationMethod,
            }) => {
              const manualMicrodeposit = "stripe_microdeposit" === verificationMethod

              let submitText = `Donate $${totalAmount} & enable Text2Give`

              if (submitUnverifiedPaymentMethod) {
                submitText = "Start bank verification"
                if (manualMicrodeposit) {
                  submitText += " & email me instructions"
                }
              }

              return (
                <div>
                  {!loading && <ErrorMessage>{error}</ErrorMessage>}
                  <SubmitActivationForm
                    loading={loading}
                    onRequestToken={requestToken}
                    paymentMethod={paymentMethod}
                    person={this.state.person}
                    submitText={submitText}
                    acceptedToS={this.state.acceptedToS}
                    url={this.props.routes.update}
                  />
                </div>
              )
            }}
          </FindPaymentMethod>
        )}
      </div>
    )
  }
}
