import React from "react"
import { string, func } from "prop-types"
import DateInput from "shared/components/date_input"
import moment from "moment"

export default class RecurringDonationStatus extends React.Component {
  static propTypes = {
    status: string.isRequired,
    release_hold_at: string.isRequired,
    onStatusChange: func.isRequired,
    onDateChange: func.isRequired,
    label: string.isRequired,
  }

  handleDateChange = ({ target: { value } }) => {
    this.props.onDateChange(value)
  }

  handleSelectChange = ({ target: { value } }) => {
    this.props.onStatusChange(value)
  }

  getOnHoldIndefinitely = () => {
    return this.props.status === "indefinite_hold"
  }

  getReleaseHoldAt = () => {
    return this.props.status === "hold_until" ? this.props.release_hold_at : ""
  }

  getTomorrow = () => {
    return moment().add(1, "days").format("YYYY-MM-DD")
  }

  renderUntilDate = () => {
    return (
      <div className="pl-1@md pt-1 pt-0@md">
        <DateInput
          className="date"
          value={this.props.release_hold_at}
          minDate={this.getTomorrow()}
          onChange={this.handleDateChange}
          aria-label="select date"
        />
      </div>
    )
  }

  render() {
    return (
      <div className="d-f@md ai-c f_1">
        <div className="pr-1@md">
          <label htmlFor="recurring_donation_status_select">{this.props.label}</label>
          <select
            value={this.props.status}
            className="select"
            id="recurring_donation_status_select"
            onChange={this.handleSelectChange}
          >
            <option value="active">Active</option>
            <option value="indefinite_hold">On hold indefinitely</option>
            <option value="hold_until">On hold through</option>
          </select>
        </div>
        {this.props.status === "hold_until" && this.renderUntilDate()}
        <input
          type="hidden"
          name="recurring_donation[on_hold_indefinitely]"
          value={this.getOnHoldIndefinitely()}
        />
        <input
          type="hidden"
          name="recurring_donation[release_hold_at]"
          value={this.getReleaseHoldAt()}
        />
      </div>
    )
  }
}
