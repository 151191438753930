import React, { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import Ladda from "shared/components/ladda"
import PersonFormFields from "church_center/components/donation_form/person_form_fields"
import PaymentMethodFormFields from "church_center/components/donation_form/payment_method_form_fields"
import { FormFor } from "react-rails-form-helpers"
import { ccoPerson } from "shared/utils/prop_types"
import { bool, func, object, string } from "prop-types"
import findPaymentMethodTokenResolver from "church_center/components/find_payment_method/find_payment_method_token_resolver"
import { css } from "glamor"

SubmitUnverifiedPaymentMethodForm.propTypes = {
  loading: bool.isRequired,
  onSubmit: func.isRequired,
  paymentMethod: object.isRequired,
  person: ccoPerson.isRequired,
  url: string.isRequired,
  verificationMethod: string.isRequired,
  autoSubmit: bool,
}

function SubmitUnverifiedPaymentMethodForm({
  loading,
  onSubmit,
  paymentMethod,
  person,
  url,
  verificationMethod,
  autoSubmit,
}) {
  const formRef = useRef()

  useEffect(() => {
    if (autoSubmit === true) {
      const form = $(ReactDOM.findDOMNode(formRef.current)).closest("form").get(0)
      Rails.fire(form, "submit")
    }
  }, [autoSubmit])

  let submitText = "Start bank verification"
  if (["stripe_microdeposit", "stripe_connections_microdeposit"].includes(verificationMethod)) {
    submitText += " & email me instructions"
  }

  return (
    <FormFor url={url} method="post" data-remote onSubmit={onSubmit} ref={formRef}>
      <PersonFormFields person={person} />
      <PaymentMethodFormFields paymentMethod={paymentMethod} />

      <div className="mt-3 ta-c">
        <Ladda
          button
          loading={loading}
          className={`btn primary-btn ${loading ? "disabled-btn" : null}`}
          {...css({
            "@media (max-width: 599px)": {
              height: "auto",
              lineHeight: "1.2",
              paddingTop: 8,
              paddingBottom: 8,
              whiteSpace: "normal",
            },
          })}
        >
          {submitText}
        </Ladda>
      </div>
    </FormFor>
  )
}

export default findPaymentMethodTokenResolver(SubmitUnverifiedPaymentMethodForm)
