import React from "react"
import { ccoPerson } from "shared/utils/prop_types"
import { node } from "prop-types"

PersonBadge.propTypes = {
  person: ccoPerson.isRequired,
  children: node,
}

export default function PersonBadge({ person, children }) {
  return (
    <div className="d-f ai-c jc-sb action-drawer">
      <div className="d-f">
        <div className="w-6 h-6 mr-2">
          <img
            src={`${person.avatar_url}?g=80x80%23`}
            className="circle"
            alt={`Image of ${person.name}`}
          />
        </div>
        <div>
          <div>{person.name}</div>
          <div className="fs-13 c-tint2">{person.email_address}</div>
        </div>
      </div>
      <div className="ta-r">{children}</div>
    </div>
  )
}
