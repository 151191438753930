import React, { useState } from "react"
import Ladda from "shared/components/ladda"
import CaptchaForm from "church_center/components/captcha_form"
import { bool, func, node } from "prop-types"
import EmailInput from "./email_input"

NewPersonForm.propTypes = {
  loading: bool.isRequired,
  onSubmit: func.isRequired,
  preCreateInformation: node,
}

export default function NewPersonForm({ loading, onSubmit, preCreateInformation }) {
  const [firstName, setFirstName] = useState()
  const showFirstNameWarning = /(&|\sand\s|&amp;|\+|\/|\\)/i.test(firstName)

  return (
    <CaptchaForm onSubmit={onSubmit}>
      <div className="mt-3">
        <label htmlFor="email_address">Email</label>
        <EmailInput
          required
          id="email_address"
          name="email_address"
          placeholder="Email"
          data-cy="new_person_form_email"
        />
      </div>
      <div className="mt-2">
        <label htmlFor="first_name">Name</label>
        <div className="d-f@sm f_1">
          <div className="pr-1@sm mb-2 mb-0@sm">
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="First name"
              data-cy="new_person_form_first_name"
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            {showFirstNameWarning && (
              <div className="c-marigold">For accuracy, use one name only.</div>
            )}
          </div>
          <div className="pl-1@sm">
            <label htmlFor="last_name" className="screen-reader-text">
              Last name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Last name"
              data-cy="new_person_form_last_name"
              required
            />
          </div>
        </div>
      </div>

      {preCreateInformation}

      <div className="ta-c">
        <Ladda button loading={loading} className="mt-3 mb-3 btn" data-cy="new_person_form_submit">
          Continue
        </Ladda>
      </div>
    </CaptchaForm>
  )
}
