import React from "react"
import { string } from "prop-types"
import Icon from "church_center/components/external_icon"

const InlineSuccessMessage = ({ className, message = "Saved!" }) => {
  return (
    <div className={`inline-success-notification ${className}`}>
      <div className="inline-success-notification--icon">
        <Icon symbol="general#thick-checkmark" />
      </div>
      <div className="inline-success-notification-text">{message}</div>
    </div>
  )
}

InlineSuccessMessage.propTypes = {
  className: string,
  message: string,
}

export default InlineSuccessMessage
