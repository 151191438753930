import React, { useEffect, useState } from "react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import useDebouncedValue from "shared/hooks/use_debounced_value"

function EmailInput(props) {
  const [address, setAddress] = useState("")
  const [suggestedReplacement, setSuggestedReplacement] = useState(undefined)
  const [showSuggestion, setShowSuggestion] = useState(false)
  const debouncedAddress = useDebouncedValue(address, 300)

  const trackEmailSuggestionsClick = () => {
    sessionApiClient.post("/accounts/v2/track_email_replacement_selected_count", {
      data: {
        attributes: {
          origin: "ccw_donation_form",
          original_address: address,
          suggested_address: suggestedReplacement,
        },
      },
    })
  }

  useEffect(() => {
    if (debouncedAddress === suggestedReplacement) return

    sessionApiClient
      .post("/accounts/v2/suggest_email_replacement", {
        data: { attributes: { email: debouncedAddress } },
      })
      .then(({ data }) => {
        setSuggestedReplacement(data.attributes.suggested_replacement)
        setShowSuggestion(!!data.attributes.suggested_replacement)
      })
      .catch(() => setShowSuggestion(false))
  }, [debouncedAddress, suggestedReplacement])

  useEffect(() => {
    if (showSuggestion && address === suggestedReplacement) {
      setShowSuggestion(false)
    }
  }, [address, suggestedReplacement, showSuggestion])

  return (
    <>
      <input
        type="email"
        value={address}
        onChange={(e) => {
          setAddress(e.target.value)
          setShowSuggestion(false)
        }}
        {...(showSuggestion && {
          style: {
            boxShadow: "none",
            outline: "none",
            borderColor: "var(--color-citrine)",
          },
        })}
        {...props}
      />
      {showSuggestion && (
        <div className="fs-4 mt-2p" style={{ color: "var(--warning-alert--text)" }}>
          Did you mean <strong>{suggestedReplacement}</strong>?{" "}
          <button
            type="button"
            className="link-btn fs-5 td-u:h"
            onClick={() => {
              setAddress(suggestedReplacement)
              setShowSuggestion(false)
              trackEmailSuggestionsClick()
            }}
            style={{
              color: "var(--color-tint2)",
            }}
          >
            Replace
          </button>
        </div>
      )}
    </>
  )
}

export default EmailInput
