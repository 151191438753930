import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import Icon from "church_center/components/external_icon"
import { string } from "prop-types"
import colors from "church_center/utils/colors"

const Circle = styled(motion.div)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: ${(props) => props.background};
`

const IconWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #fff;
  }
`

export const DonationStatusIconSuccess = ({ background = colors.emerald }) => {
  return (
    <Circle
      background={background}
      initial={{ opacity: 0.15, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
    >
      <IconWrapper
        initial={{ opacity: 0, scale: 0.1, rotate: -60 }}
        animate={{ opacity: 1, scale: 1, rotate: 0 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
          delay: 0.1,
        }}
      >
        <Icon symbol="general#thick-checkmark" style={{ width: "56%", height: "56%" }} />
      </IconWrapper>
    </Circle>
  )
}

DonationStatusIconSuccess.propTypes = {
  background: string,
}

export const DonationStatusIconError = ({ background = colors.ruby }) => {
  return (
    <Circle
      background={background}
      initial={{ opacity: 0.15, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
    >
      <IconWrapper
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 50,
          damping: 40,
        }}
      >
        <Icon symbol="general#thick-x" style={{ width: "48%", height: "48%" }} />
      </IconWrapper>
    </Circle>
  )
}

DonationStatusIconError.propTypes = {
  background: string,
}
